<template>
  <div class="user-video">
    <div class="user-title-box">
      <div class="title">我的视频</div>
    </div>
    <div class="content">
      <div class="list-player">
        <watch-item v-if="Boolean(videoList.length)" :list="videoList" :isShow="false" >
          <template v-slot:delete="item">
            <a-button type="danger" @click="deleteVideo(item)" >删除视频</a-button>
          </template>
        </watch-item>
        <a-empty v-else>
          <template #description>
            <p>暂无视频</p>
          </template>
        </a-empty>
      </div>
    </div>

    <div class="page">
      <a-pagination
        v-model:current="curPage"
        v-model:pageSize="pageSize"
        :total="total"
        show-size-changer
        show-quick-jumper
      >
        <template #itemRender="{ page, type, originalElement }">
          <a v-if="type === 'prev'">上一页</a>
          <a v-else-if="type === 'next'">下一页</a>
          <renderVNode v-else :vnode="originalElement">{{ page }}</renderVNode>
        </template>
      </a-pagination>
      
    </div>

    <!--
      @showSizeChange="onShowSizeChange"
     -->
    <!-- <a-pagination v-model:current="current" :total="50" show-less-items /> -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, toRefs } from 'vue';
import WatchItem from '@/components/WatchItem.vue';
import { fieldName, video_attribute, typeText } from '@/store/fieldConfiguration';
import api from '@/services';
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    WatchItem
  },
  setup() {
    const videoList = ref([]);
    const state = reactive({
      curPage: 1,
      pageSize: 10,
      total: 8
    });

    const renderVNode = (_, { attrs: { vnode } }) => {
      return vnode;
    };

    const userProductList = async params => {
      try {
        let { data, success } = await api.userProductList(params);
        console.log(data, '======base');
        if (success) {
          videoList.value = data?.records.map(item => {
            item.type = typeText[item.type];
            let paramsJson = JSON.stringify(item);
            video_attribute.forEach((oldKey, index) => {
              const reg = oldKey;
              paramsJson = paramsJson.replace(reg, fieldName[index]);
            });
            return JSON.parse(paramsJson);
          });
        }
      } catch (error) {
        console.error('我的视频列表接口错误！');
      }
    };

    const deleteVideo = async (params) => {
      try {
        let { success } = await api.userDeleteVideo(params.item.id);
        if(success) {
          userProductList({
            curPage: state.curPage,
            pageSize: state.pageSize
          });
          message.success('删除成功！');
        }
      } catch (error) {
        console.error('删除视频接口错误！');
      }
    }

    onMounted(() => {
      userProductList({
        curPage: state.curPage,
        pageSize: state.pageSize
      });
    });

    // const onShowSizeChange = (current: number, pageSize: number) => {
    //   console.log(current, pageSize);
    // };
    // watch(pageSize, () => {
    //   console.log('pageSize', pageSize.value);
    // });
    // watch(current1, () => {
    //   console.log('current', current1.value);
    // });

    return {
      videoList,
      userProductList,
      // current: 2,
      // pageSize,
      // current1,
      // current2,
      // onShowSizeChange,

      ...toRefs(state),
      renderVNode,
      deleteVideo
    };
  }
});
</script>

<style lang="less" scoped>
@import '../../../assets/less/public.less';
.user-video {
  .user-title-box {
    display: flex;
    justify-content: space-between;
    padding-right: 45px;
  }
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #007fff;
    line-height: 28px;
  }

  .content {
    margin-top: 38px;
  }

  .attention-and-watch {
    grid-template-columns: repeat(4, 264px);
  }
  .page {
    margin-top: 30px;
  }

  // &_box {
  //   width: 270px;
  //   height: 100px;
  //   display: flex;
  //   align-items: center;
  //   flex-wrap: nowrap;
  //   background: #f6f6f6;
  //   border-radius: 51px;
  // }

  // &_box-right {
  //   margin-left: 16px;
  // }

  // &_name {
  //   font-size: 16px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #333333;
  //   line-height: 22px;
  // }

  // &_avatar {
  //   width: 100px;
  //   height: 100px;
  //   background: #007fff;
  //   border-radius: 50px;
  //   overflow: hidden;
  // }

  // &_id-box {
  //   height: 38px;
  //   display: flex;
  //   align-items: center;
  //   flex-wrap: nowrap;

  //   .icon-live-id {
  //     width: 16px;
  //     height: 16px;
  //     // background: url('@{icon_live_id}') no-repeat center / 100% 100%;
  //   }

  //   .user-follow_id {
  //     margin-left: 5px;
  //     font-size: 16px;
  //     font-family: PingFangSC-Medium, PingFang SC;
  //     font-weight: 500;
  //     color: #666666;
  //     line-height: 22px;
  //   }
  // }

  // &_unfollow {
  //   width: 68px;
  //   height: 20px;
  //   line-height: 20px;
  //   background: #d4d4d4;
  //   border-radius: 10px;
  //   font-size: 12px;
  //   font-family: PingFangSC-Regular, PingFang SC;
  //   font-weight: 400;
  //   color: #ffffff;
  //   text-align: center;
  //   cursor: pointer;

  //   &:hover {
  //     background: #007fff;
  //   }
  // }
}
</style>
